.fc {
	background-color: #ffffff;
}

.fc .fc-button-group > .fc-button {
	text-transform: capitalize;
}

.fc-daygrid-event {
	text-align: center;
	white-space: normal;
}
